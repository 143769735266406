import AOS from 'aos';
import 'magnific-popup';
import 'slick-carousel';
import Swiper from 'swiper/js/swiper.min';
import Parallax from 'parallax-js';
import './TweenMax.min.js';

const $win = $(window);
const $doc = $(document);

const $template_dir = crbSiteData.dir

AOS.init({
	once: true,
});

$doc.on('ready', function(){
	contactPopup();
	linkPopups();
	blueLineMovement();
	sliderTestimonials();
	imageParallax();
	mouseParallax();
	infoContentSlide();
	instaSlider();
	rowsMovement();
	initialize_maps();
	formAnimations();
	
	locationsSliders();	
	homeIntroAnimation();
	menuTrigger();
	menuMobile();
	accordionScript();
	initTabs();
	initImageTextSlider();
	initLocationSlider();
	initPartnersSlider();
	footerForm();
	loginDropdown();
	introScroll();
})

$doc.on('ready', function() {
	let $infoReadMoreBtn = $('.section-info .section__content-actions a');

	if ( $infoReadMoreBtn.length ) {
		$infoReadMoreBtn.click();
	}
})

$win.on('load scroll', function(){
	let scrollTop = $win.scrollTop();
	let $header = $('.header')

	if ( scrollTop > 25 ) {
		$header.addClass('scrolled')
	} else {
		$header.removeClass('scrolled')
	}
})

function isMobile() {
	return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const loginDropdown = () => {
	$('.header__login > a').on('click', function(e){
		e.preventDefault();

		if ( isMobile() ) {
			$(this).next().addClass('mobile-active');
			$('.header .header__inner').addClass('mobile-active');
		} else {
			$('body').toggleClass('login-menu-open');
		}
	})

	$('.header__dropdown-mobile-close').on('click', function(e){
		e.preventDefault();

		$(this).parent().removeClass('mobile-active');
		$('.header .header__inner').removeClass('mobile-active');
	})

	$doc.on('click', function(e){
		if ( $('body').hasClass('login-menu-open') ) {
			let container = $(".header__login");
			
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				$('body').removeClass('login-menu-open');
			}
		}
	})
}

const menuMobile = () => {
	$win.on('load', function(){
		let sub_button = '<a href="#" class="sub_menu_back">Back</a>';

		$('.nav > ul > .menu-item-has-children > ul').each(function(){
			$(this).prepend(sub_button);
		})		
	})

	$('.nav .menu-item-has-children > a').on('click', function(e){
		if (isMobile()) {
			e.preventDefault();

			$(this).next().addClass('mobile-active');
			$('.header .header__inner').addClass('mobile-active');
		}
	} );

	$('.nav').on('click', '.sub_menu_back', function(e){
		e.preventDefault();

		$('.nav').find('.mobile-active').removeClass('mobile-active');
		$('.header .header__inner').removeClass('mobile-active');
	})
}

const homeIntroAnimation = () => {
	if ( $('.intro').length ) {
		var Colors = function() {
		  this.pink = "#f48991";
		  this.yellow = "#fec238";
		  this.lgreen = "#9bd3ae";
		  this.green = "#009192";
		  this.red = "#b52432";
		  this.blue = "#0066c6";
		  this.dark = "#00243b";
		  this.blank = "transparent";
		  this.orange = "#f65b38";
		};

		Colors.prototype.getColor = function(col) {
		  return this[col];
		};

		var Plus = function(lw) {
			this.x = 0;
			this.y = 0;

			this.top = 0;
			this.left = 0;

			this.height = 0;
			this.width = 0;
			this.scale = 1;

			this.lineWidth = lw;

			this.color = "#ffffff";
		};

		//Add draw method to the object
		Plus.prototype.draw = function(ctx, x, y) {
			ctx.save();
			ctx.strokeStyle = this.color;
			ctx.beginPath();
			ctx.setTransform(
				this.scale,
				0,
				0,
				this.scale,
				this.left + this.x,
				this.top + this.y
			);
			ctx.lineWidth = this.lineWidth;

			ctx.moveTo(0, -this.height);
			ctx.lineTo(0, this.height );

			ctx.stroke();
			ctx.closePath();
			ctx.restore();
		};

		var c = document.getElementById("c");
		var context = c.getContext("2d");
		var signs = [];
		var mouse = { x: 0, y: 0 };
		var gridWidth = 7;
		var gridHeight = 7;
		var mouseOver = false;
		var mouseMoved = false;
		var colors = new Colors();

		var squares = [
			{
				width: 26,
				height: 48,
				top: 54,
				left: 25,
				color: "pink",
			},
			{
				width: 35,
				height: 48,
				top: 58,
				left: 70,
				color: "yellow",
			},
			{
				width: 35,
				height: 49,
				top: 49,
				left: 128,
				color: "lgreen",
			},
			{
				width: 35,
				height: 48,
				top: 52,
				left: 181,
				color: "red",
			},
			{
				width: 35,
				height: 48,
				top: 52,
				left: 300,
				color: "dark",
			},
			{
				width: 9,
				height: 48,
				top: 52,
				left: 347,
				color: "yellow",
			},
			{
				width: 26,
				height: 48,
				top: 124,
				left: 25,
				color: "green",
			},
			{
				width: 35,
				height: 48,
				top: 123,
				left: 70,
				color: "lgreen",
			},
			{
				width: 35,
				height: 48,
				top: 123,
				left: 126,
				color: "dark",
			},
			{
				width: 35,
				height: 48,
				top: 119,
				left: 235,
				color: "pink",
			},
			{
				width: 35,
				height: 48,
				top: 119,
				left: 302,
				color: "yellow",
			},
			{
				width: 9,
				height: 48,
				top: 119,
				left: 347,
				color: "green",
			},
			{
				width: 17,
				height: 48,
				top: 189,
				left: 21,
				color: "red",
			},
			{
				width: 35,
				height: 48,
				top: 196,
				left: 71,
				color: "orange",
			},
			{
				width: 35,
				height: 48,
				top: 188,
				left: 127,
				color: "blue",
			},
			{
				width: 35,
				height: 48,
				top: 188,
				left: 177,
				color: "yellow",
			},
			{
				width: 61,
				height: 48,
				top: 188,
				left: 244,
				color: "lgreen",
			},
			{
				width: 35,
				height: 48,
				top: 185,
				left: 308,
				color: "pink",
			},
			{
				width: 35,
				height: 48,
				top: 260,
				left: 28,
				color: "orange",
			},
			{
				width: 35,
				height: 48,
				top: 260,
				left: 76,
				color: "dark",
			},
			{
				width: 17,
				height: 48,
				top: 254,
				left: 139,
				color: "green",
			},
			{
				width: 35,
				height: 48,
				top: 257,
				left: 186,
				color: "red",
			},
			{
				width: 18,
				height: 48,
				top: 249,
				left: 248,
				color: "yellow",
			},
			{
				width: 18,
				height: 48,
				top: 257,
				left: 295,
				color: "blue",
			},
			{
				width: 52,
				height: 104,
				top: 370,
				left: 63,
				color: "yellow",
			},
			{
				width: 52,
				height: 52,
				top: 394,
				left: 143,
				color: "yellow",
			},
			{
				width: 69,
				height: 52,
				top: 394,
				left: 290,
				color: "yellow",
			},
		]

		c.width = 382;
		c.height = 460;

		// Create sign grid using 2D array
		for (var i = 0; i < squares.length; i++) {
			signs[i] = [];
			
			signs[i] = new Plus(squares[i].width);
			signs[i].left = squares[i].left;
			signs[i].top = squares[i].top;
			signs[i].width = squares[i].width;
			signs[i].height = squares[i].height / 2;
			signs[i].color = colors.getColor(squares[i].color);
			
		}

		TweenLite.ticker.addEventListener("tick", draw);

		function draw() {
			context.clearRect(0, 0, c.width, c.height);

			if (mouseOver && mouseMoved) {
				calculateSigns();
				mouseMoved = false;
			}

			for (var i = 0; i < squares.length; i++) {
				var sign = signs[i];
				sign.draw(context);	
			}
		}

		function calculateSigns() {
			for (var i = 0; i < squares.length; i++) {		
				var sign = signs[i];
				var hyp = Math.min(c.width, c.height) / (gridWidth + 1) / 2;
				var d = dist([sign.left, sign.top], [mouse.x, mouse.y]);
				var ax = mouse.x - sign.left;
				var ay = mouse.y - sign.top;
				var angle = Math.atan2(ay, ax);
				if (d < hyp + sign.width) {
					hyp = d;
					TweenMax.to(sign, 0.3, { scale: 2 });
				} else {
					TweenMax.to(sign, 0.3, { scale: 1 });
				}

				TweenMax.to(sign, 0.3, {
					x: Math.cos(angle) * hyp,
					y: Math.sin(angle) * hyp
				});
			}
		}

		c.addEventListener("mousemove", mouseMove);
		c.addEventListener("touchmove", mouseMove);

		function mouseMove(e) {
			if (e.targetTouches && e.targetTouches[0]) {
				e = e.targetTouches[0];
			}

			var rect = c.getBoundingClientRect();
			mouse.x = e.clientX - rect.left;
			mouse.y = e.clientY - rect.top;
			mouseMoved = true;
		}

		c.addEventListener("mouseenter", function() {
			mouseOver = true;
		});

		c.addEventListener("touchstart", function(e) {
			mouseOver = true;
			mouseMove(e);
		});

		c.addEventListener("mouseleave", mouseLeave);
		c.addEventListener("touchend", mouseLeave);

		function mouseLeave() {
			mouseOver = false;

			for (var i = 0; i < squares.length; i++) {
				var sign = signs[i];
				if (!mouseOver) {
					TweenMax.to(sign, 0.3, { x: 0, y: 0, scale: 1 });
				}	
			}
		}

		function dist([x1, y1], [x2, y2]) {
			var dx = x1 - x2;
			var dy = y1 - y2;
			return Math.sqrt(dx * dx + dy * dy) || 1;
		}
	}
}

const contactPopup = () => {
	if ( $('.contact-popup').length ) {
		let $contactPopup = $('.contact-popup');

		$('.header .header__join a, .btn--contact-popup').on('click', function(e){
			e.preventDefault();

			$contactPopup.addClass('active');

			setTimeout(function(){
				$contactPopup.addClass('overflow');
			}, 800)
		})

		$contactPopup.find('.contact__close').on('click', function(e){
			e.preventDefault()

			$contactPopup.removeClass('active overflow');
		})

		$contactPopup.find('.contact__overlay').on('click', function(){
			$contactPopup.removeClass('active overflow');
		})
	}
}

const linkPopups = () => {
	$('.btn-watch').magnificPopup({
		type: 'iframe',
		iframe: {
		patterns: {
			wistia: {
					index: 'wistia.com',
					id: function(url) {        
						var m = url.match(/^.+wistia.com\/(medias)\/([^_]+)[^#]*(#medias=([^_&]+))?/);

						if (m !== null) {
							if(m[4] !== undefined) {
								return m[4];
							}
						return m[2];
					}

					return null;
				},

					src: '//fast.wistia.net/embed/iframe/%id%?autoPlay=1'
				}
			}
		}
	});
}

const blueLineMovement = () => {
	let $parent = $('.section-image-columns');
	
	if ( $parent.length ) {
		let parentTop = $parent.offset().top;
		let parentHalfHeight = $parent.outerHeight() / 2;
		let parentCenter = parentTop + parentHalfHeight;
		let $line = $('.section-image-columns .section__animation');
		let addition;
		
		$win.on('resize', function(){
			parentTop = $parent.offset().top;
			parentHalfHeight = $parent.outerHeight() / 2;
			parentCenter = parentTop + parentHalfHeight;
		})

		$win.on('scroll', function(){
			let scrollTop = $win.scrollTop();

			if ( scrollTop >= parentTop ) {
				addition = scrollTop - parentTop;

				$line.css('height', addition);
			}
		})
	}
}

const sliderTestimonials = () => {
	if ( $('.slider-testimonials').length ) {
		let $sliderTestimonials = $('.slider-testimonials .slider__slides').slick({
			dots: true,
			appendDots: $('.slider-testimonials .slider__actions'),
			fade: true,
			autoplay: true,
			autoplaySpeed: 8000,
			arrows: false,
			pauseOnHover: false,
		})
	}
}

const unslickTestimonials = () => {
	if ( $('.slider-testimonials').length ) {
		$('.slider-testimonials .slider__slides').slick('unslick');
	}
}

const unslickImages = () => {
	if ( $('.section-featured .slider__slides').length ) {
		$('.section-featured .slider__slides').slick('unslick');
	}
}



const imageParallax = () => {
	if ( $('.parallax').length ) {
		$win.on('scroll load', function(){
			let scrollTop = $win.scrollTop();
			let $parallaxParentTop = $('.parallax-parent').offset().top;

			$('.parallax').each(function(){
				let $this = $(this);

				let parallaxSpeed= $this.data('speed');

				if ( scrollTop >= $parallaxParentTop ) {}

				let yPos = (scrollTop - $parallaxParentTop)*parallaxSpeed;

				let coords = `translateY(-${yPos}px)`;

				$this.css({ transform: coords });
			})
		})
	}
}

const mouseParallax = () => {
	if ( $('#scene').length ) {
		let scene = $('#scene').get(0);
		let parallaxInstance = new Parallax(scene, {

		});
	}
}

const infoContentSlide = () => {
	if ( $('.section-info').length ) {
		let $content = $('.section-info .section__content')
		let contentHeight = $content.outerHeight();
		$content.css('height', contentHeight);

		let botPadding = 210;

		if ( $win.width() < 768 ) {
			botPadding = 115;
		}

		let parentHeight = $content.closest('.section__right').outerHeight();
		let remainingHeight = parentHeight - $('.section-info .section__content-head').outerHeight() - botPadding

		let $text = $('.section-info .section__content-body');

		$win.on('resize', function(){
			contentHeight = $content.outerHeight();
			parentHeight = $content.closest('.section__right').outerHeight();
			remainingHeight = parentHeight - $('.section-info .section__content-head').outerHeight() - botPadding

			$('.section-info .section__content-actions a').removeClass('open');
			$text.css('height', 0);
			$content.css('height', 'auto');
		})

		$('.section-info .section__content-actions a').on('click', function(e){
			e.preventDefault();

			let $this = $(this);

			if ( $this.hasClass('open') ) {
				$this.removeClass('open');
				$text.css('height', 0);
				$content.css('height', contentHeight);
			} else {
				$this.addClass('open');
				$text.slideDown();
				$text.css('height', remainingHeight);
				$content.css('height', parentHeight);
			}
		})
	}
}

const instaSlider = () => {
	if ( $('.section-instagram').length ) {
		const instagramSlider = new Swiper('.section-instagram .swiper-container', {
			slidesPerView: 'auto',
			centeredSlides: true,
			spaceBetween: 15,
			freeMode: true,
			loop: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
		});
	}
}

const rowsMovement = () => {
	if ( $('.section-rows').length ) {
		$('.section-rows .section__box-actions a').on('click', function(e){
			e.preventDefault();

			$(this).toggleClass('open').closest('.section__box').toggleClass('active').siblings().toggleClass('notActive');
		})
	}
}

const initialize_maps = () => {
	if ( ! $('.section__map').length ) {
		return;
	}

	var maps = $('.section__map').each(function () {

		var locations = JSON.parse(crbSiteData.locationMapMarkers);

		var $this = $(this);

		var map;
		var marker,
		i,
		bounds;

		var mapOptions = {
			zoom: 7,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			draggable: true,
			panControl: false,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			overviewMapControl: true,
			fullscreenControl: false,
			gestureHandling: 'cooperative',
			styles: [{"featureType": "all", "elementType": "labels.text.fill", "stylers": [{"saturation": 36 }, {"color": "#333333"}, {"lightness": 40 } ] }, {"featureType": "all", "elementType": "labels.text.stroke", "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16 } ] }, {"featureType": "all", "elementType": "labels.icon", "stylers": [{"visibility": "off"} ] }, {"featureType": "administrative", "elementType": "geometry.fill", "stylers": [{"color": "#fefefe"}, {"lightness": 20 } ] }, {"featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{"color": "#fefefe"}, {"lightness": 17 }, {"weight": 1.2 } ] }, {"featureType": "landscape", "elementType": "geometry", "stylers": [{"color": "#f5f5f5"}, {"lightness": 20 } ] }, {"featureType": "poi", "elementType": "geometry", "stylers": [{"color": "#f5f5f5"}, {"lightness": 21 } ] }, {"featureType": "poi.park", "elementType": "geometry", "stylers": [{"color": "#dedede"}, {"lightness": 21 } ] }, {"featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{"color": "#ffffff"}, {"lightness": 17 } ] }, {"featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{"color": "#ffffff"}, {"lightness": 29 }, {"weight": 0.2 } ] }, {"featureType": "road.arterial", "elementType": "geometry", "stylers": [{"color": "#ffffff"}, {"lightness": 18 } ] }, {"featureType": "road.local", "elementType": "geometry", "stylers": [{"color": "#ffffff"}, {"lightness": 16 } ] }, {"featureType": "transit", "elementType": "geometry", "stylers": [{"color": "#f2f2f2"}, {"lightness": 19 } ] }, {"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#e9e9e9"}, {"lightness": 17 } ] } ],
		}

		map = new google.maps.Map(this, mapOptions);

		bounds = new google.maps.LatLngBounds();


		for (i = 0; i < locations.length; i++) {
			marker = new google.maps.Marker({
				position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
				map: map,
				icon: new google.maps.MarkerImage( locations[i].icon, null, null, null, new google.maps.Size(28,28)),
			});

			bounds.extend({ lat: locations[i].lat, lng: locations[i].lng})
		}

		map.fitBounds(bounds);
	})
}

const formAnimations = () => {
	if ( $(".gform_wrapper").length ) {
		$win.on('load scroll', function(event){
			$(".gform_wrapper").each(function(i, el) {
				var el = $(el);
				if (el.visible(true)) {
					el.addClass("visible"); 
				}
			});
		});
	}
}

$.fn.visible = function(partial) {
	
  var $t            = $(this),
	  $w            = $(window),
	  viewTop       = $w.scrollTop(),
	  viewBottom    = viewTop + $w.height(),
	  _top          = $t.offset().top,
	  _bottom       = _top + $t.height(),
	  compareTop    = partial === true ? _bottom : _top,
	  compareBottom = partial === true ? _top : _bottom;

return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

};

const locationsSliders = () => {
	if ( $('.intro-locations').length ) {
		const imageSlider = new Swiper('.intro__slider-image .swiper-container', {
			slidesPerView: 1,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			allowTouchMove: false,
		});

		const citiesSlider = new Swiper('.intro__slider-nav .swiper-container', {
			slidesPerView: 4,
			direction: 'vertical',
			centeredSlides: true,
			mousewheel: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true,
			},
			on: {
				click: function() {
					let indexClicked = this.clickedIndex;

					this.slideTo(indexClicked);
				},
				slideChange: function() {
					$('.intro__slider-icon').hide(0)
				}
			}
		})

		citiesSlider.controller.control = imageSlider;
	}
}


const menuTrigger = () => {
	$('.nav-trigger').on('click', function(e){
		e.preventDefault();

		$(this).next().addClass('active');
	})

	$('.header__mobile-close').on('click', function(e){
		e.preventDefault();

		console.log('test');

		$(this).closest('.header__inner').removeClass('active');
	})
}

const accordionScript = () => {
	if ( $('.accordion').length ) {
		$('.accordion').on('click', '.accordion__head', function (event) {
			$(this).next().stop().slideToggle()
				.parent().toggleClass('accordion__section--current')
				.siblings().removeClass('accordion__section--current')
					.find('.accordion__body').slideUp();
			
		});
	}
}

const initTabs = () => {
	if ( $('.tabs:not(.tabs--inactive)').length ) {
			if ( $('.intro__actions-fixed').length ) {
				let actions = $('.intro__actions').offset().top

				$win.on('scroll', function(){
					let scrollTop = $win.scrollTop();

					if ( scrollTop > (actions + 60) ) {
						$('.intro__actions-fixed').addClass('show')
					} else {
						$('.intro__actions-fixed').removeClass('show')
					}
				})
			}

		$('.tabs .tab a').on('click', function(e){
			e.preventDefault();

			let $this = $(this);
			let hash = $this.attr('href');
			let $tabsBody = $('.tabs-body');

			$this.closest('.tab').addClass('active').siblings().removeClass('active');

			$('.intro-location-single .intro__image').each(function(){
				$(this).find('[data-id="' + hash + '"]').addClass('active').siblings().removeClass('active')
			})

			$('.intro-location-single .intro__actions').find('[data-id="' + hash + '"]').addClass('active').siblings().removeClass('active')
			$('.intro-location-single .intro__actions-fixed').find('[data-id="' + hash + '"]').addClass('active').siblings().removeClass('active')

			$tabsBody.find('[data-id="' + hash + '"]').fadeIn().siblings().fadeOut();

			setTimeout(function(){
				$( 'html, body' ).animate({
					scrollTop: $tabsBody.offset().top - 75
				}, 800);
			}, 1000)

			initialize_maps();

			unslickTestimonials();
			unslickImages();
			sliderTestimonials();
			initLocationSlider();
		})
	}
}

const initImageTextSlider = () => {
	if ( $('.slider-tiles').length ) {
		$('.slider-tiles .slider__slides').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: true,
			dots: false,
		})
	}
}

const initLocationSlider = () => {
	if ( $('.section-featured .section__slider').length ) {
		$('.section-featured .slider__slides').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: true,
			dots: false,
			autoplay: true,
		})
	}
}


const initPartnersSlider = () => {
	if ( $('.section-box-slider').length ) {
		$('.section-box-slider .slider__slides').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: true,
			appendArrows: $('.section-box-slider .slider__actions'),
			dots: false,
		})
	}
}

const footerForm = () => {
	if ( $('.footer__callout--form').length ) {
		$('.footer__callout--form a').on('click', function(e){
			e.preventDefault();

			$(this).closest('.footer__callout').addClass('footer__callout--show-form');
		})
	}
}

const introScroll = () => {
	if ( $('.intro .scroll-down').length ) {
		let $nextSection = $('.intro').next();

		if ( $nextSection.length ) {
			$('.intro .scroll-down').on('click', function(e){
				e.preventDefault();

				$('html, body').animate({
					scrollTop: $nextSection.offset().top - $('.header').outerHeight() + 2 + 'px'
				}, 500);
			});
		}
	}
}

$win.on( 'load hashchange', function(e) {
	var hash = window.location.hash;
	var location = window.location;

	if ( hash.length && $('ul.tabs').length ) {

		// Trigger Area Page Tab
		let $tabToTrigger = $('ul.tabs li.tab a[href="' + hash + '"]');
		$tabToTrigger.click();

		// Close mobile menu if open

		if ( isMobile() ) {
			$('.nav').find('.mobile-active').removeClass('mobile-active');
			$('.header .header__inner').removeClass('mobile-active active');
		}

		// Remove and assign correct current-menu-item class

		let $parentMenuItemAnchor = $('.nav').find('a[href="' + location + '"]');
		$parentMenuItemAnchor.parent('li').addClass('current-menu-item').siblings().removeClass('current-menu-item');
	}
});